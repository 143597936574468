import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProductListTable({
  productList,
  handleApiCall,
  storeData,
}) {
  const [expressCheckoutData, setExpressCheckoutData] = React.useState([]);
  console.log("🚀 ~ file: ProductListTable.js:28 ~ storeData:", storeData);
  console.log(
    "🚀 ~ file: ProductListTable.js:23 ~ ProductListTable ~ productList:",
    productList
  );

  const countMap = {};
  productList?.forEach((item) => {
    const key = `${item.shelfName}-${item.scaleIdentifier}`;
    countMap[key] = (countMap[key] || 0) + 1;
  });

  React.useEffect(() => {
    const tempData = productList
      ?.map((row, ind) => {
        const keyVal = `${row.shelfName}-${row.productPosition}`;
        return {
          ...row,
          customPosition: keyVal,
        };
      })
      ?.sort((a, b) => {
        const aParts = (a.customPosition || "").split("-");
        const bParts = (b.customPosition || "").split("-");

        const a1 = parseInt(aParts[0]) || 0; // Handle non-number or empty cases
        const a2 = aParts[1] || ""; // Default to empty string if missing
        const a3 = parseInt(aParts[2]) || 0; // Handle non-number or empty cases

        const b1 = parseInt(bParts[0]) || 0;
        const b2 = bParts[1] || "";
        const b3 = parseInt(bParts[2]) || 0;

        return a1 - b1 || a2.localeCompare(b2) || a3 - b3;
      });

    console.log("tempdata", tempData);

    setExpressCheckoutData(tempData);
  }, [productList]);
  console.log("🚀 ~ countMap:", countMap);

  return (
    <TableContainer
      sx={{ height: "100%", border: "1px solid black", resize: "vertical" }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{ fontWeight: 800 }}>Image</StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>
              Product Name
            </StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>Position</StyledTableCell>
            <StyledTableCell sx={{ fontWeight: 800 }}>Action</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {expressCheckoutData?.map((row) => {
            return (
              <StyledTableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row?.product?.imageUrl ? (
                    <div className="w-10 h-10  flex items-center justify-center">
                      <img src={row?.product?.imageUrl} alt="" />
                    </div>
                  ) : (
                    <div className="w-[46px] h-[46px]  flex items-center justify-center">
                      {/* <img src={NoImage} alt="No Image" /> */}
                    </div>
                  )}
                  {/* <div className="w-10 h-10  flex items-center justify-center">
                  <img src={row?.product?.imageUrl} alt="" />
                </div> */}
                </StyledTableCell>
                <StyledTableCell>{row?.product?.name}</StyledTableCell>
                <StyledTableCell>{row?.customPosition}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleApiCall({
                        id: storeData?.id,
                        storeCartItems: [
                          {
                            productId: row?.product?.id,
                            quantity: 1,
                            note: "",
                            scaleGroupId: row?.scaleGroupId,
                            scaleIdentifier: row?.scaleIdentifier,
                            activityType: "PICK",
                          },
                        ],
                      })
                    }
                  >
                    Add Item
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
