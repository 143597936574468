import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import { useGet } from "../../../../../../api/useGet";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { isAnyValuesEmpty } from "../../../../../../utils/utils";
import { usePost } from "../../../../../../api/usePost";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DesktopDateTimePicker from "../../../../../../components/DateTimePicker";

const RequestGhostShopperVideo = ({ open, onClose }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [formData, setFormData] = useState({
    storeCode: "",
    // entryTime: "",
    // exitTime: "",
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeZone, setTimeZone] = useState("Europe/Berlin");

  const { data: allRetailderStores } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&size=100&sort=createdDate,desc`,
    "allRetailderStores"
  );

  const {
    mutate: reqCartVideos,
    isSuccess,
    reset,
    isLoading,
  } = usePost(
    `${process.env.REACT_APP_STORE_URL}/user-activities/ghost-shopper-video
`,
    ["reqCartVideos"]
  );

  console.log("isLoading", isLoading);

  console.log("allRetailderStores", allRetailderStores);

  const onChangeTime = (date, dateString, name) => {
    console.log(date, dateString, "onChangeTime");
    setFormData((data) => ({
      ...data,
      [name]: date,
    }));
  };

  const handleSubmit = () => {
    const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

    const data = {
      ...formData,
      entryTime: dayjs.utc(startTime).format(dateTimeFormat) + "Z",
      exitTime: dayjs.utc(endTime).format(dateTimeFormat) + "Z",
    };
    reqCartVideos(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Video requested successfully.");
      reset();
      onClose();
    }
  }, [isSuccess]);

  const onChangeEntryTime = (value, name) => {
    setStartTime(value);
  };

  const onChangeExitTime = (value, name) => {
    setEndTime(value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          textAlign="center"
          sx={{ mb: 2 }}
        >
          Request cart videos
        </Typography>
        {/* <Divider sx={{ mb: 3 }} /> */}
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Store</p>
          <Select
            value={
              allRetailderStores?.find(
                (item) => item?.internalCode == formData?.storeCode
              )?.name
            }
            onChange={(e) => {
              setTimeZone(
                allRetailderStores?.find(
                  (item) => item?.internalCode == e.target.value
                )?.timeZone
                  ? allRetailderStores?.find(
                      (item) => item?.internalCode == e.target.value
                    )?.timeZone
                  : "Europe/Berlin"
              );
              setFormData((data) => ({
                ...data,
                storeCode: e.target.value,
              }));
            }}
            fullWidth
            // displayEmpty
            // sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>
              Store
            </MenuItem>
            {allRetailderStores?.map((store) => (
              <MenuItem value={store?.internalCode}>{store?.name}</MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Entry Time</p>

          <div style={{ width: "100%" }}>
            <DesktopDateTimePicker
              onChange={onChangeEntryTime}
              timeZone={timeZone}
              // label="Entry Time"
              value={startTime}
              name="entryTime"
              slotProps={{
                textField: {
                  // size: "small",
                  error: false,
                },
              }}
              ampm={false}
            />
          </div>
          {/* <DatePicker
            showTime
            style={{ width: "100%", height: "56px" }}
            // value={formData?.entryTime}
            onChange={(date, dateString) =>
              onChangeTime(date, dateString, "entryTime")
            }
            name={"entryTime"}
            label={"Entry Time"}
          /> */}
        </div>
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Exit Time</p>
          <div style={{ width: "100%" }}>
            <DesktopDateTimePicker
              onChange={onChangeExitTime}
              timeZone={timeZone}
              // label="Exit Time"
              value={endTime}
              name="exitTime"
              slotProps={{
                textField: {
                  // size: "small",
                  error: false,
                },
              }}
              ampm={false}
            />
          </div>
          {/* <DatePicker
            showTime
            style={{ width: "100%", height: "56px" }}
            // value={formData?.exitTime}
            // clearIcon={false}
            // onChange={onChangeTime}
            onChange={(date, dateString) =>
              onChangeTime(date, dateString, "exitTime")
            }
            name={"exitTime"}
            label={"Exit Time"}
          /> */}
        </div>
        {/* <Divider sx={{ mb: 3 }} /> */}
        <LoadingButton
          fullWidth
          color="success"
          variant="contained"
          // disabled={isAnyValuesEmpty(formData)}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Submit request
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default RequestGhostShopperVideo;
