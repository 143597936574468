import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Card, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import StickyHeadTable from "./OpenCartsTable";
import { useGet } from "../../../../../../../api/useGet";
import axios from "axios";
import { toast } from "react-toastify";
import ProductListTable from "../../../hawkeye/dashboard/ProductListTable";
import { useEffect, useState } from "react";
import SelectedProduct from "./SelectedProductTable";
import { usePut } from "../../../../../../../api/usePut";
import CameraModal from "./modal/CameraModal";
import moment from "moment";
import ModalTileVideoRequestForm from "./ModalTileVideoRequestForm";
import CoffeeEventModel from "../../../hawkeye/dashboard/model/CoffeeEventModel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "50%", // Divide the height equally
  },
}));

const ExpressCheckOut = () => {
  const classes = useStyles();

  const history = useHistory();

  const {
    state: { data, entriesImages },
  } = useLocation();
  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:35 ~ ExpressCheckOut ~ entriesImages:",
    entriesImages
  );
  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:30 ~ ExpressCheckOut ~ data:",
    data
  );

  const [storeData, setStoreData] = useState([]);

  const [apicall, setApiCall] = useState(true);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [tileRequestOpen, setTileRequestOpen] = useState(false);

  const [videoData, setVideoData] = useState({});
  const [tileForm, setTileForm] = useState({});

  const { data: userActivities } = useGet(
    `${process.env.REACT_APP_STORE_URL}/user-activities?eventStatus.equals=CHECKEDOUT&sort=entryTime,asc&cartRef.equals=${data?.sessionRef}&eventTime.greaterThanOrEqual=${data?.entryTime}&eventTime.lessThanOrEqual=${data?.checkoutDate}&storeCode.equals=${data?.storeCode}`,
    "userActivities"
  );
  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:55 ~ ExpressCheckOut ~ userActivities:",
    userActivities?.[0]?.shopperVideoPaths
  );

  const { data: storeCarts } = useGet(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/carts?sessionRef.equals=${data?.sessionRef}&sort=createdDate,desc&page=0&size=50`,
    ["storesCart", apicall]
  );

  const { data: productList } = useGet(
    `${process.env.REACT_APP_BASE_URL}/scale-products?sort=createdDate%2Cdesc&rootOrgId.equals=${data?.rootOrgId}&retailStoreId.equals=${data?.storeId}&projection=ScaleProductListDTO&size=25&page=0`,
    "productList"
    // data?.rootOrgId == 7601
  );

  const { data: storeCartsWithRackInfo, isLoading } = useGet(
    `${process.env.REACT_APP_STORE_URL}/store-carts?cartRef.equals=${data?.sessionRef}&sort=eventTime,desc&size=50`,
    "storeCartswithRackInfo"
  );

  const { data: OpenCartData } = useGet(
    `${process.env.REACT_APP_STORE_URL}/store-carts/open-cart?storeCode.equals=${entriesImages?.retailStoreCode}&eventTime.greaterThanOrEqual=${entriesImages?.entryTime}&eventTime.lessThanOrEqual=${entriesImages?.exitTime}&sort=eventTime%2Casc&syncStatus.in=INITIALIZED%2CCONFLICT%2CERRORED%2CCART_REVIEWED%2CPROCESSED&cartRef.equals=${entriesImages?.cartRef}&page=0&size=50`,
    ["openCart"]
  );

  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:91 ~ ExpressCheckOut ~ OpenCartData:",
    OpenCartData
  );

  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:35 ~ ExpressCheckOut ~ productList:",
    productList
  );

  useEffect(() => {
    if (storeCarts?.length > 0) {
      setStoreData(storeCarts?.[0]);
    }
  }, [storeCarts]);
  console.log(
    "🚀 ~ file: ExpressCheckout.jsx:51 ~ useEffect ~ storeCarts:",
    storeCarts
  );

  const { mutate } = usePut(
    `${process.env.REACT_APP_SHOPPER_URL}/stores/cart-finalize`,
    "hawkeyeList"
  );

  const handleApiCall = async (productData) => {
    // setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SHOPPER_URL}/stores/modify-cart`;
      await axios({
        method: "PUT",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
        data: productData,
      });
      setApiCall(!apicall);
      toast.success("Item added!!");
      // setLoading(false);
    } catch (error) {
      // setLoading(false);

      // Handle any errors
      toast.error(error?.message);
      // console.error(error);
    }
  };

  const confirm = () => {
    try {
      mutate({ ...storeData, status: "APPROVED" });
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        history.push("/support/shopper-activity");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const rejected = () => {
    mutate({ ...storeData, status: "REJECTED" });
    history.push("/support/hawkeye");
  };

  const handleOpen = (item) => {
    setVideoData(item);
    setOpen(true);
  };

  const handleClose = () => {
    setVideoData({});
    setOpen(false);
  };

  const handleTileRequestOpen = () => {
    setTileRequestOpen(true);
  };

  const handleTileRequestClose = () => {
    setTileRequestOpen(false);
  };

  const [isCoffeeEventModal, setIsCoffeeEventModal] = useState(false);

  const handleCloseCoffevent = () => setIsCoffeeEventModal(false);
  const handleOpenCoffevent = () => setIsCoffeeEventModal(true);
  return (
    <Container maxWidth="2xl">
      <Card sx={{ my: 3, p: 2, height: "80vh" }}>
        <Grid container spacing={2} height="80vh">
          {/* Left Column */}
          <Grid item xs={6} height="100%">
            {/* Single row in the left column */}
            <Grid container height="100%">
              {/* <Card sx={{ height: "100%" }}> */}
              <Grid item xs={12} pb={2} height="100%">
                {/* Content for the left column */}
                <ProductListTable
                  productList={productList}
                  handleApiCall={handleApiCall}
                  storeData={storeData}
                />
              </Grid>
              {/* </Card> */}
            </Grid>
          </Grid>

          {/* Right Column */}
          <Grid item xs={6} height="100%">
            {/* Two rows in the right column */}
            <Grid container spacing={2} height="100%" gap={2}>
              <Grid item xs={12} maxHeight="50%">
                {/* Content for the first row in the right column */}
                <StickyHeadTable OpenCartData={OpenCartData} />
              </Grid>
              <Grid item xs={12} maxHeight="50%">
                {/* Content for the second row in the right column */}
                <SelectedProduct
                  storeData={storeData}
                  setStoreData={setStoreData}
                  storeCartsWithRackInfo={storeCartsWithRackInfo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={3}
      >
        <Button variant="contained" color="success" onClick={handleOpenCoffevent}>
          Open coffee events
        </Button>
        {/* <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {userActivities?.[0]?.shopperVideoPaths?.map((item) => (
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => handleOpen(item)}
            >
              {item?.name}
            </Button>
          ))}
        </Box> */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="outlined" color="error" onClick={rejected}>
            Reject
          </Button>
          {storeData?.storeCartItems?.length > 0 && (
            <Button
              variant="contained"
              color="success"
              sx={{ ml: 2 }}
              onClick={confirm}
            >
              Approve
            </Button>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => handleTileRequestOpen()}
            style={{ backgroundColor: "#1b5e20" }}
          >
            Request Tile Videos
          </Button>
        </Box>
      </Box>
      {open && (
        <CameraModal open={open} videoData={videoData} onClose={handleClose} />
      )}
      <ModalTileVideoRequestForm
        open={tileRequestOpen}
        onCloseModal={handleTileRequestClose}
        cartRef={storeData?.sessionRef}
        entryTime={storeData?.entryTime}
        exitTime={storeData?.checkoutDate}
        storeCode={storeData?.storeCode}
      />
      {isCoffeeEventModal && (
        <CoffeeEventModel
          open={isCoffeeEventModal}
          handleClose={handleCloseCoffevent}
          data={storeData}
        />
      )}
    </Container>
  );
};

export default ExpressCheckOut;
